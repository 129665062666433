
import * as React from 'react'
import { AppBar, Toolbar } from "@mui/material"
import { Button } from 'gatsby-material-ui-components'
import { Box } from '@mui/system'

const NavBar = (props) => {
    return (
        <AppBar position="static" color="transparent" sx={{
            boxShadow: 'none'
        }}>
            <Toolbar variant="regular" component="nav">
                <Box display="flex" flexGrow={1}>
                </Box>
                <Button to="/" color="inherit">Home</Button>
            </Toolbar>
        </AppBar>
    )
}

export default NavBar