import { Paper, Typography } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import NavBar from './navbar'


const Layout = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    return (
        <div>
            <Helmet>
                <title>{data.site.siteMetadata.title} | {pageTitle}</title>
            </Helmet>
            <StaticImage
                style={{ position: 'static', maxWidth: '100vw', maxHeight: '100vh', float: 'left', pointerEvents: 'none' }}
                layout='fullWidth'
                src={'../images/background.jpg'}
                formats={['auto', 'webp', 'avif']}
                alt=""
            />
            <NavBar></NavBar>
            <Paper
                variant='outlined'
                sx={{
                    margin: 'auto',
                    maxWidth: '80vw',
                    position: 'relative',
                    padding: '25px',
                    backgroundColor: 'rgba(18, 18, 18, 0.75)',
                    height: 'calc(100vh - 64px)',
                    '@media (max-width:638px)': {
                        maxWidth: '100vw'
                    }
                }}
            >
                <main>
                    <header>
                        <Typography variant='h2' sx={{
                            textAlign: 'center',
                            '@media screen and (max-width: 450px)': {
                                fontSize: '3rem'
                            }
                        }}>
                            {pageTitle}
                        </Typography>
                    </header>

                    {children}
                </main>
            </Paper>
        </div>
    )
}

export default Layout